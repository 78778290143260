<template>
    <div class="page">
        <div class="option-box">
            <div class="left">
                <Form :model="formItem" inline>
                    <FormItem label="发布时间">
                        <DatePicker v-model="formItem.publishTimeStart" type="date"
                            placeholder="开始时间"
                            format="yyyy-MM-dd"
                            @on-change="changeRangeVal($event,'publishTime',0)"
                            style="width: 120px;"></DatePicker>
                        <span class="split">至</span>
                        <DatePicker v-model="formItem.publishTimeEnd" type="date"
                            placeholder="截止时间"
                            format="yyyy-MM-dd"
                            @on-change="changeRangeVal($event,'publishTime',1)"
                            style="width: 120px"></DatePicker>
                    </FormItem>
                    <!-- <FormItem label="成果类型">
                        <Select v-model="formItem.resultType" clearable filterable>
                            <Option v-for="(item,index) in optionList.resultType" :key="index" :value="item.id">{{item.name}}</Option>
                        </Select>
                    </FormItem> -->
                </Form>
                <Form :model="formItem" inline>
                    <FormItem label="内容类型">
                        <Select v-model="formItem.detailsType" clearable filterable multiple max-tag-count="2" style="width: 275px;">
                            <Option v-for="(item,index) in optionList.detailsType" :key="index" :value="item.name">{{item.name}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="搜索内容">
                        <Input v-model="formItem.title" style="width: 202px;"/>
                    </FormItem>
                </Form>
            </div>

            <div class="right">
                <div class="btn searchBtnLog" data-id="1" @click="search">查询</div>
            </div>
        </div>

        <div class="report-box normal">
            <div class="title">常规报告</div>
            <div class="list">
                <div class="report-item" v-for="(item,index) in normal" :key="index"  @click="gotoDetail(item)">
                    <img :src="item.pictureUrl" :alt="item.title" :title="item.overview">
                    <div class="report-title" :title="item.title">{{item.title}}</div>
                    <!-- <div class="cover">
                        <img :src="item.pictureUrl" :alt="item.title">
                    </div> -->
                </div>
            </div>

            <Page
                :total="normalTotal"
                :page-size="6"
                @on-change="getList($event,1)"
            />
        </div>

        <div class="report-box pro">
            <div class="title">专题报告</div>
            <div class="list">
                <div class="report-item" v-for="(item,index) in pro" :key="index" @click="gotoDetail(item)">
                    <div class="left">
                        <Icon type="ios-play" />
                        <div class="item-title" :title="item.overview" >{{item.title}}</div>
                    </div>
                    <div class="mid">
                        <div class="type">{{item.detailsName}}</div>
                    </div>
                    <div class="right">
                        <div class="time">{{formatDate(item.publishTime)}}</div>
                    </div>
                </div>
            </div>

            <Page
                :total="proTotal"
                @on-change="getList($event,2)"
            />
        </div>

        <div class="report-box pro">
            <div class="title">深度研究报告</div>
            <div class="list">
                <div class="report-item" v-for="(item,index) in deep" :key="index" @click="gotoDetail(item)">
                    <div class="left">
                        <Icon type="ios-play" />
                        <div class="item-title" :title="item.overview" >{{item.title}}</div>
                    </div>
                    <div class="mid">
                        <div class="type">{{item.detailsName}}</div>
                    </div>
                    <div class="right">
                        <div class="time">{{formatDate(item.publishTime)}}</div>
                    </div>
                </div>
            </div>

            <Page
                :total="deepTotal"
                @on-change="getList($event,3)"
            />
        </div>
    </div>
</template>

<script>
import mixin from '@/mixins';
import formMixin from '@/mixins/form';
import {formatDate} from '@/utils'
import {deep_attention_page , deep_type} from '@/api/reSearch';
import dataReports from '@/mixins/dataReports'
export default {
    name : "深度研究",
    mixins : [mixin,formMixin,dataReports],
    data() {
        return {
            formItem : {
                // resultType : 1,
                detailsType : "",
                publishTime : [],
                search : "",
            },
            optionList : {
                resultType : [
                    {
                        id : 1,
                        name : '常规报告'
                    },
                    {
                        id : 2,
                        name : '专题报告'
                    },
                    {
                        id : 3,
                        name : '深度研究报告'
                    },
                ],
                detailsType : [
                    {
                        id : 1,
                        name : '综合'
                    },
                    {
                        id : 2,
                        name : '经济'
                    },
                    {
                        id : 3,
                        name : '土地'
                    },
                    {
                        id : 4,
                        name : '政策'
                    },
                ]
            },

            current : 1,
            pageSize : 10,

            normal : [],
            normalTotal : 0,
            pro : [],
            proTotal : 0,
            deep : [],
            deepTotal : 0,
        }
    },
       beforeMount(){
     this.$Notice.destroy();
    },
    mounted() {
        deep_type().then(res => {
            this.optionList.detailsType = res.map((item,index) => {
                return {
                    id : index,
                    name : item
                }
            });
        })
        this.search();
    },
    methods : {
        formatDate(data) {
            return formatDate(data);
        },
        gotoDetail(item) {
            if(!item.perm) {
            //    this.$Notice.warning({
            //       title: '温馨提示',
            //       desc: `${this.noticeInfo.noAuthority[1003]}`,
            //       duration: 5
            //     });
          this.$msg.error({ type: "noAuthority", code: 1003 });


                return;
            }
            this.$router.push({
                path : '/Research/ResearchReportDetail',
                query : {
                    id : item.id,
                    pageId:this.$route.meta.pageId,
					pageName:this.$route.name
                }
            });
        },
        search() {
            this.getList(1,1);
            this.getList(1,2);
            this.getList(1,3);
        },
        getList(page,type) {
            let formItem = this.formItem;
            deep_attention_page({
                current : page,
                detailsType : formItem.detailsType,
                publishTime : (formItem.publishTime[0] && formItem.publishTime[1]) ? formItem.publishTime : [],
                resultType : type,
                size : type == 1 ? 6 : this.pageSize,
                title : formItem.title
            }).then(data => {
                switch(type) {
                    case 1 :
                        this.normal = data.records;
                        this.normalTotal = data.total;
                        break;
                    case 2 :
                        this.pro = data.records;
                        this.proTotal = data.total;
                        break;
                    case 3 :
                        this.deep = data.records;
                        this.deepTotal = data.total;
                        break;
                }
            })
        },
        pageChange(page,type) {
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/common.scss';
.page {
	min-height: 100%;
	min-width: 1200px;
    padding: 20px;
    overflow: hidden;

    .option-box {
        @include flex;
        justify-content: flex-start;
        margin: 20px 0;
        padding: 0 30px;
        border-radius: 10px;
        border: 1px solid #E3E3E5;
        /deep/ .ivu-form-item {
            margin-right: 60px;
            .ivu-form-item-label {
                width: 70px;
            }
            .ivu-form-item-content {
                display: inline-block;
            }
        }
        .split {
            margin: 0 10px;
        }
        .left {
            padding-top: 24px;
        }
        .right {
            @include flex;
            // margin-left: auto;
            .btn {
                @include flex;
                width: 144px;
                height: 40px;
                margin-right: 20px;
                color: #fff;
                font-size: 18px;
                border-radius: 5px;
                background-color: #00B6FF;
                cursor: pointer;
            }
        }
    }

    .report-box {
        margin-top: 20px;
        .title {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: bold;
        }
        &.normal {
            .list {
                @include flex;
                justify-content: flex-start;
                padding-top: 20px;
                border-top: 1px solid #D6D7D9;
            }
            .report-item {
                // width: 140px;
                flex : 1;
                max-width: 256px;
                margin-right: 20px;
                // background-color: #eee;
                cursor: pointer;

                img {
                    width: 100%;
                    // height: 192px;
                }

                .report-title {
                    @include ellipsis;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        &.pro {
            .list {
                padding-top: 20px;
                border-top: 1px solid #D6D7D9;
            }
            .report-item {
                @include flex;
                height: 44px;
                font-size: 16px;
                border-bottom: 1px dotted #D6D7D9;
                cursor: pointer;
                .left {
                    @include flex(flex-start);
                    flex: 1;
                    .item-title {
                        // margin-right: auto;
                        @include ellipsis;
                        // max-width: 50%;
                    }
                }
                .mid {
                    @include flex;
                    flex: 1;
                    .type {
                        @include flex;
                        min-width: 60px;
                        height: 24px;
                        padding: 0 10px;
                        margin: 0 10px;
                        // margin-left: auto;
                        // margin-right: auto;
                        color: #1562D6;
                        font-size: 12px;
                        border: 1px solid #1562D6;
                    }
                }
                .right {
                    @include flex(flex-end);
                    flex: 1;
                    .time {
                        // margin-left: 20px;
                        color: #999;
                        font-size: 14px;
                    }
                }
            }
        }

        /deep/ .ivu-page {
            margin-top: 10px;
            text-align: right;
        }
    }
}
</style>